<template>
  <MCard class="hotel-search-card">
    <div class="search-bar">
      <HotelDestinationCombobox
        v-model:input-value="destination"
        v-model:input-text="destination"
        label="Where"
        placeholder="Destination"
        :is-loading="$store.getters.isLocationsFetching"
        :options="locations"
        prepend-icon="m-hotel"
        @update:inputText="searchLocation"
        @update:input-value="onSelectLocation"
        :has-error="!!errors?.destination"
        :error-message="errors?.destination"
        class="destination"
      />
      <MDatePicker
        label="Check In"
        :startDate="startDate"
        :endDate="endDate"
        :min-date="minimumDate"
        @onRangeDateChange="onRangeDateChange"
        @rangeStartDate="onRangeStartDate"
        placeholder="Check In"
        dateType="startDate"
        :hasRange="true"
        :multiCalendar="true"
        position="center"
        :auto-position="false"
        :isFocused="isCheckInDateFocused"
        :error="errors?.checkIn"
      />
      <MDatePicker
        label="Check Out"
        :startDate="startDate"
        :endDate="endDate"
        :min-date="minimumDate"
        @onRangeDateChange="onRangeDateChange"
        @rangeStartDate="onRangeEndDate"
        placeholder="Check Out"
        dateType="endDate"
        :multiCalendar="true"
        :hasRange="true"
        position="right"
        :auto-position="false"
        :isFocused="isCheckOutDateFocused"
        :error="errors?.checkOut"
        ref="MCheckOutDatePickerRef"
      />
      <MTravelerCountBox
        class="destination"
        label="Guests"
        prepend-icon="m-traveler-count-icon"
        :adult-count="$store.state.hotelModule.hotel_adult_count"
        :child-count="$store.state.hotelModule.hotel_child_count"
        :infant-count="0"
      >
        <template #items>
          <MTravelerCountBoxItem
            name="adultCount"
            label="Adult"
            icon="m-adult-traveler-icon"
            hint="(12 years and above)"
            :count="adultCount"
            @update:adultCount="updateAdultCount"
          />
          <MTravelerCountBoxItem
            name="childCount"
            label="Children"
            icon="m-child-traveler-icon"
            hint="(0 to 17 years)"
            :count="childCount"
            @update:childCount="updateChildCount"
          />
          <div
            v-if="$store.state.hotelModule.hotel_child_count > 0"
            class="combobox-grid"
          >
            <div v-for="(item, index) in childCount" :key="index">
              <MCombobox
                v-model:inputValue="childrenAges[index]"
                :options="childAges"
                :label="renderAgeSelecterLabel(index)"
                itemValue="value"
                itemLabel="label"
                variant="outlined"
                @update:inputValue="(value: any) => handleAgeSelectChange(value, index)"
                class="child_age_select"
                focusedBorderEnabled
              />
            </div>
          </div>
        </template>
      </MTravelerCountBox>
    </div>
    <div class="search-button">
      <MButton @click="handleSearchClick()" :disabled="isPropertiesFetching">
        Search Hotels
      </MButton>
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { addDays, isBefore } from "date-fns";
import { debounce, NumericDictionary } from "lodash";
import {
  HotelSearchSuggestion,
  HotelBookingErrors,
  RoomOccupancyChildrenParams,
} from "@/ag-portal-common/types/hotel";
import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import { ChildrenAges } from "@/ag-portal-common/constants/hotels";
import { PATH } from "@/ag-portal-common/constants/path";
import { formatQueryParamsforHotelSearch } from "../utils";
import analyticsService from "@/analytic.service";
import { HOTEL_ANALYTICS_EVENTS } from "@/modules/Hotel/constants/analyticsEvents";
import HotelDestinationCombobox from "./HotelDestinationCombobox.vue";
import { MCombobox, MDatePicker } from "@aeroglobe/ag-core-ui";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";

export default defineComponent({
  name: "HotelSearchBar",
  components: {
    HotelDestinationCombobox,
    MDatePicker,
    MCombobox,
  },
  data() {
    return {
      isCheckInDateFocused: false,
      isCheckOutDateFocused: false,
      destination: "",
      childrenAges: [] as number[],
      isInitialRender: true,
      adultCount: 1,
      childCount: 0,
      locationQuery: "",
      minimumDate: addDays(new Date(), 1),
      childAges: ChildrenAges,
      endDate: new Date() as Date | null,
      startDate: new Date() as Date | null,
      errors: {
        destination: "",
        checkIn: "",
        checkOut: "",
        adultCount: "",
        childCount: "",
        childAges: "",
      } as HotelBookingErrors,
    };
  },
  methods: {
    onRangeEndDate(date: Date) {
      if (this.startDate) {
        if (isBefore(date, this.startDate as Date)) {
          this.changeCheckInDate(date);
          this.changeCheckOutDate(null);
          this.startDate = date;
          this.endDate = null;
        } else {
          this.changeCheckOutDate(date);
          this.endDate = date;
          this.closeCheckOutDatePicker();
        }
      } else {
        this.changeCheckInDate(date);
        this.changeCheckOutDate(null);
        this.startDate = date;
        this.endDate = null;
      }
    },
    closeCheckOutDatePicker() {
      const mDatePicker = this.$refs.MCheckOutDatePickerRef as InstanceType<
        typeof MDatePicker
      > & {
        closeMenu: () => void;
      };

      if (mDatePicker) {
        mDatePicker.closeMenu();
      }
    },
    onRangeDateChange(dates: any) {
      if (dates?.length > 1) {
        this.startDate = new Date(dates[0]);
        this.changeCheckInDate(dates[0]);
        this.endDate = new Date(dates[1]);
        this.changeCheckOutDate(dates[1]);
        this.minimumDate = new Date();
      }

      this.isCheckInDateFocused = false;
      this.isCheckOutDateFocused = false;
    },
    onRangeStartDate(date: Date) {
      this.startDate = date;
      this.endDate = null;
      this.isCheckInDateFocused = false;
      this.isCheckOutDateFocused = true;
    },
    changeCheckInDate(newDate: Date) {
      this.$store.commit("saveCheckInDate", newDate);
    },
    changeCheckOutDate(newDate: Date | null) {
      this.$store.commit("saveCheckOutDate", newDate);
    },
    validateDetails() {
      this.errors = {
        destination: "",
        checkIn: "",
        checkOut: "",
        adultCount: "",
        childCount: "",
        childAges: "",
      };
      const checkIn = this.startDate;
      const checkOut = this.endDate;
      const destination = this.destination;
      let hasError = false;
      if (checkIn && checkOut) {
        if (checkIn >= checkOut) {
          this.errors.checkOut =
            "Check Out date can't be same or before check in";
          hasError = true;
        }
      }
      if (destination === null || destination === "") {
        this.errors.destination = "Hotel details are required";
        hasError = true;
      }
      if (this.childrenAges.length !== this.childCount) {
        if (this.childCount > this.childrenAges.length) {
          while (this.childrenAges.length < this.childCount) {
            this.childrenAges.push(0);
          }
        } else if (this.childCount < this.childrenAges.length) {
          this.childrenAges = this.childrenAges.slice(0, this.childCount);
        }
      }

      return hasError;
    },
    searchLocation(value: string) {
      this.locationQuery = value;
    },
    onSelectLocation(value: any) {
      this.locationQuery = value;
      this.$store.commit("saveSelectedLocation", value);
    },
    searchLocations(value: string) {
      if (value && value.length > 2) {
        this.$store.dispatch("fetchLocations", value);
      }
    },
    updateAdultCount(value: number) {
      this.adultCount = value;
      this.$store.commit("saveHotelAdultCount", value);
    },
    updateChildCount(value: number) {
      this.childCount = value;
      this.$store.commit("saveHotelChildCount", value);
    },
    handleAgeSelectChange(value: string, index: number) {
      const ages = this.$store.state.hotelModule.hotel_child_age || [];
      this.childrenAges[index] = Number(value);
      ages[index] = Number(value);
      this.$store.commit("updateChildrenAges", ages);
    },
    renderAgeSelecterLabel(index: number) {
      const age = `Child ${index + 1} Age`;
      return age;
    },
    handleSearchClick() {
      const {
        hotel_adult_count,
        hotel_child_count,
        selectedLocation,
        checkin_date,
        checkout_date,
      } = this.$store.state.hotelModule;

      if (selectedLocation !== null && !this.validateDetails()) {
        const query = formatQueryParamsforHotelSearch(
          this.adultCount,
          this.childrenAges,
          selectedLocation,
          checkin_date,
          checkout_date
        );
        const path = `${PATH.HOTELS_SEARCH_RESULT}${formatQueryPath(query)}`;

        const payload = {
          to: `${selectedLocation.display_name}, ${selectedLocation.sub_display_name}`,
          type: selectedLocation.type,
          "check-in": checkin_date,
          "check-out": checkout_date,
          "adult-travler-count": hotel_adult_count,
          "child-travler-count": hotel_child_count,
        };

        analyticsService.logActionEvent(
          HOTEL_ANALYTICS_EVENTS.HOTEL_SEARCH,
          payload
        );

        this.$router.push(path);
      } else {
        this.validateDetails();
      }
    },
  },
  computed: {
    isPropertiesFetching(): boolean {
      return this.$store.getters.isPropertiesFetching;
    },
    locations(): HotelSearchSuggestion[] {
      return this.$store.getters.locations as Array<HotelSearchSuggestion>;
    },
    checkoutMinDate(): Date {
      const minDate = this.$store.state.hotelModule.checkout_date
        ? this.$store.state.hotelModule.checkout_date
        : addDays(new Date(), 1);
      return minDate;
    },
    checkInMinDate(): Date {
      return this.$store.state.hotelModule.checkin_date ?? new Date();
    },
    hotelChildAges(): number[] {
      return this.$store.getters.hotelChildAges;
    },
    renderTravelerLabel() {
      const { hotel_adult_count, hotel_child_count } =
        this.$store.state.hotelModule;
      let label = "";
      if (hotel_adult_count > 0) {
        label += `${hotel_adult_count} Adult`;
        if (hotel_adult_count > 1) {
          label += "s";
        }
      }

      if (hotel_child_count > 0) {
        if (label !== "") {
          label += ", ";
        }
        label += `${hotel_child_count} Child`;
        if (hotel_child_count > 1) {
          label += "ren";
        }
      }

      return label;
    },
  },
  mounted() {
    this.isInitialRender = true;
    this.destination =
      this.$store.state.hotelModule.selectedLocation?.label ?? "";
    this.startDate = this.$store.state.hotelModule.checkin_date ?? new Date();
    this.endDate =
      this.$store.state.hotelModule.checkout_date ?? addDays(new Date(), 1);
    this.childCount = this.$store.state.hotelModule.hotel_child_count ?? 0;
    this.childrenAges = this.$store.state.hotelModule.hotel_child_age
      ? this.$store.state.hotelModule.hotel_child_age.map(
          (child: any) => child.age ?? 0
        )
      : [0];
    setTimeout(() => {
      this.isInitialRender = false;
    }, 1000);
  },
  watch: {
    locationQuery: {
      handler: debounce(function (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.searchLocations(value);
      }, 500),
      immediate: true,
    },
    "$store.state.hotelModule.hotel_child_count": {
      handler: function (value) {
        const ages = this.$store.state.hotelModule.hotel_child_age || [];
        if (value > ages?.length) {
          ages.push(0);
        } else if (value < ages?.length) {
          ages.pop();
        }
        this.$store.commit("updateChildrenAges", ages);
      },
      immediate: true,
    },
  },
});
</script>

<style lang="css" scoped>
.combobox-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 5px;
}
.child_age_select {
  padding: 5px;
}
.search-button {
  display: flex;
  justify-content: center;
}
.search-bar {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.search-bar > div {
  flex: 1;
}

@media (max-width: 768px) {
  .search-bar > div {
    flex: 1 1 100%;
  }
}
.hotel-search-card {
  margin-bottom: 10px;
}
</style>
