import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d64dc01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "accordion-title-container" }
const _hoisted_3 = { class: "accordion-title" }
const _hoisted_4 = { class: "link-financial-profile-btn-container" }
const _hoisted_5 = { class: "content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganizationDetailsShimmer = _resolveComponent("OrganizationDetailsShimmer")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!
  const _component_AgentsDataTable = _resolveComponent("AgentsDataTable")!
  const _component_MAccordion = _resolveComponent("MAccordion")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_FinancialProfile = _resolveComponent("FinancialProfile")!
  const _component_OrganizationDetails = _resolveComponent("OrganizationDetails")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_LinkFinancialProfileModal = _resolveComponent("LinkFinancialProfileModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.fetchingPriceDetails)
      ? (_openBlock(), _createBlock(_component_OrganizationDetailsShimmer, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.errorOrgnatizationNotFound)
            ? (_openBlock(), _createBlock(_component_ResultNotFound, { key: 0 }))
            : _createCommentVNode("", true),
          (!!_ctx.organization)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.searchedEmail)
                  ? (_openBlock(), _createBlock(_component_MAccordion, {
                      key: 0,
                      "initial-open": true
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("span", _hoisted_3, "Search Results - " + _toDisplayString(_ctx.searchedEmail), 1)
                        ])
                      ]),
                      content: _withCtx(() => [
                        _createVNode(_component_AgentsDataTable, {
                          "organization-id": _ctx.organization?.public_id,
                          "searched-email": _ctx.searchedEmail,
                          "has-pagination": false,
                          "has-search": false
                        }, null, 8, ["organization-id", "searched-email"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.isSuperUser)
                    ? (_openBlock(), _createBlock(_component_MButton, {
                        key: 0,
                        variant: "filled",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLinkFinancialProfileModalOpen = true))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.renderLinkFinancialButtonTitle), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters.organization
          ?.financial_profiles, (financialProfile, index) => {
                  return (_openBlock(), _createBlock(_component_FinancialProfile, {
                    key: index,
                    "financial-profile": financialProfile,
                    "enable-actions": _ctx.isSuperUser
                  }, null, 8, ["financial-profile", "enable-actions"]))
                }), 128)),
                _createVNode(_component_OrganizationDetails, {
                  organization: _ctx.$store.getters.organization,
                  onRefetchOrganizationDetails: _ctx.handleRefetchOrganizationDetails
                }, null, 8, ["organization", "onRefetchOrganizationDetails"]),
                _createVNode(_component_MAccordion, { "initial-open": true }, {
                  title: _withCtx(() => [
                    _createVNode(_component_AgIconInfoBar, {
                      "test-id": "",
                      title: "Agents",
                      class: "section-title",
                      mPrepandIcon: "m-agents"
                    })
                  ]),
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_AgentsDataTable, {
                        "organization-id": _ctx.organization?.public_id,
                        "has-add-new-agent": true
                      }, null, 8, ["organization-id"])
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 64)),
    _createVNode(_component_LinkFinancialProfileModal, {
      "organization-id": _ctx.organization?.public_id,
      "is-open": _ctx.isLinkFinancialProfileModalOpen,
      "is-management-page": _ctx.isManagementPage,
      onToggleModal: _ctx.toggleLinkFinancialProfileModal
    }, null, 8, ["organization-id", "is-open", "is-management-page", "onToggleModal"])
  ], 64))
}