import { FareOption } from "@/ag-flight-components/types";
import { ROUTE_TYPE } from "@/ag-portal-common/enums/ROUTE_TYPE";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { IconName } from "@aeroglobe/ag-core-ui/dist/src/components/material/icons/icons.type";

export interface APIResponse<T> extends IAGResponse {
  success: boolean;
  status: number;
  data: {
    data: T;
  };
}

export interface PollingData {
  keep_polling: boolean;
}

export type PollingCallback<T, Q> = (query: Q) => Promise<APIResponse<T>>;
export type PollingEmitCallback<T> = (results: T | null) => void;
export type BookingInitiatedCallback = (bookingId: string | null) => void;

export interface DateOption {
  departureDate: string;
  arrivalDate?: string;
  active: boolean;
}

export enum AmountType {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}

export enum FareType {
  BASE = "BASE",
  GROSS = "GROSS",
}

export enum ListViewType {
  SINGLE = "SINGLE",
  PAIR = "PAIR",
}

export interface QueryData {
  route_type: ROUTE_TYPE;
  adult: string;
  child: string;
  infant: string;
  cabin_class: string;
  trips: string;
  fp?: string;
  pollId?: string;
}

export interface QueryLeg {
  origin: string;
  destination: string;
  departure_date: string;
  active?: boolean;
}

export interface SelectedQueryLeg {
  origin: string;
  destination: string;
  index: number;
}

export interface QueryType {
  route_type: ROUTE_TYPE;
  traveler_count: {
    adult_count: number;
    child_count: number;
    infant_count: number;
  };
  cabin_class: string;
  non_stop_flight: boolean;
  origin?: string;
  destination?: string;
  departure_date?: string;
  return_date?: string | null;
  legs?: QueryLeg[];
  financial_profile_id?: string;
  poll_id: string | null;
  full_result: boolean;
}

export enum FilterType {
  PRICE = "PRICE",
  STOPS = "STOPS",
  TIMES = "TIMES",
  AIRLINES = "AIRLINES",
}

export interface Filter {
  label: string;
  value: boolean;
  count: number;
  type: FilterType;
  icon?: IconName;
}

export interface RouteDetails {
  originCode: string;
  originTime: string;

  destinationCode: string;
  destinationTime: string;

  duration: string;

  stops: string[];
}

export interface SelectedFlight {
  logo: string;
  departureTime: string;
  arrivalTime: string;
}

export interface PolicyRule {
  rule: string;
  value: string;
}

export interface FlightRulesResponse {
  cancellation_policy: {
    policy_rules: PolicyRule[];
  };
  modification_policy: {
    policy_rules: PolicyRule[];
  };
  baggage_policy: {
    policy_rules: PolicyRule[];
  };
}

export interface TransformedFareOption extends FareOption {
  showInfo: boolean;
}

export interface TemperFare {
  amount: number;
  amountType: AmountType;
  fareType: FareType;
}

export interface BreakPoint {
  isDesktop: boolean;
  isLaptop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}
