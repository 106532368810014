import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import { formatMultiCityQueryParamToArray } from "@/ag-flight-components/utils";
import { QueryData, QueryType } from "../types";

export class SearchQuery {
  public query: QueryType;

  constructor(private queryData: QueryData) {
    this.query = this.initiateQuery();
  }

  updatePollId(pollId: string): void {
    this.query.poll_id = pollId;
  }

  private initiateQuery(): QueryType {
    const tripsString = this.queryData.trips.toString();
    const [origin, destination, departure_date, return_date] =
      tripsString.split(",");

    const normalFlightPayload = {
      origin,
      destination,
      departure_date,
      return_date: return_date || null,
    };

    const legs = formatMultiCityQueryParamToArray(this.queryData.trips);

    const multiCityFlightPayload = { legs };

    return {
      route_type: this.queryData.route_type,
      traveler_count: {
        adult_count: Number(this.queryData.adult),
        child_count: Number(this.queryData.child),
        infant_count: Number(this.queryData.infant),
      },
      cabin_class: this.queryData.cabin_class,
      full_result: false,
      non_stop_flight: false,
      ...(this.isNormalFlight() && normalFlightPayload),
      ...(this.isMultiCityFlight() && multiCityFlightPayload),
      ...(this.queryData.fp && { financial_profile_id: this.queryData.fp }),
      poll_id: this.queryData.pollId ?? null,
    };
  }

  private isNormalFlight(): boolean {
    return [ROUTE_TYPE.ONEWAY, ROUTE_TYPE.RETURN].includes(
      this.queryData.route_type
    );
  }

  private isMultiCityFlight(): boolean {
    return this.queryData.route_type === ROUTE_TYPE.MULTI_CITY;
  }
}
