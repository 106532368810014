<template>
  <MDialog
    title="Selected Hotel for FIT"
    v-model="isMdialogOpen"
    :maxWidth="900"
  >
    <template #content>
      <div v-if="hasEmailItem">
        <div v-for="(booking, bookingKey) in emailItems" :key="bookingKey">
          <FITHotelSelectedInfo
            :checkinDate="booking.check_in"
            :checkoutDate="booking.check_out"
            :noOfNight="booking.nights"
            :totalCost="round(booking.total_selling_price)"
            :hotelName="booking.hotel_name"
            :roomType="booking.room_name"
            :travelers="booking.total_passengers"
          />
        </div>
      </div>
      <div v-if="hasPreviewItem">
        <FITHotelSelectedInfo
          :checkinDate="previewItems.check_in"
          :checkoutDate="previewItems.check_out"
          :noOfNight="previewItems.nights"
          :totalCost="round(previewItems.total_selling_price)"
          :hotelName="previewItems.hotel_name"
          :roomType="previewItems.room_name"
          :travelers="previewItems.total_passengers"
        />
      </div>

      <MTextarea
        v-model:inputValue.trim="query"
        label="Comments"
        :hasError="queryError.length"
        :errorMessage="queryError"
      />
    </template>
    <template #actions>
      <MButton @click="addNextItinerary()" variant="primary">
        Add Next Itinerary
      </MButton>

      <MButton @click="sendFITEmailOnPreview()" type="outlined">
        Continue by sending email
      </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { FITBookingPayload, ItineraryInfo } from "../constants";
import FITHotelSelectedInfo from "../components/SelectedHotelInfo.vue";
import { MDialog } from "@aeroglobe/ag-core-ui";
export default defineComponent({
  name: "PackageDetailModal",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    FITHotelSelectedInfo,
    MDialog,
  },
  data() {
    return {
      query: "",
      isMdialogOpen: this.isOpen,
      queryError: "",
    };
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
  computed: {
    hasPreviewItem(): boolean {
      return this.$store.getters.previewItems;
    },
    hasEmailItem(): boolean {
      return this.$store.getters.emailItems;
    },
    previewItems(): ItineraryInfo {
      return this.$store.getters.previewItems;
    },
    emailItems(): ItineraryInfo[] {
      return this.$store.getters.emailItems;
    },
  },
  methods: {
    round(amount: string): number {
      var sellingPrice = parseFloat(amount);
      return Number(sellingPrice.toFixed(2));
    },
    addNextItinerary() {
      this.$store.commit("addFITEmailItems", this.previewItems);
      this.$emit("closeModal");
    },
    async sendFITEmailOnPreview() {
      if (this.query.trim().length > 0) {
        this.queryError = "";
        this.$store.commit("addFITEmailItems", this.previewItems);
        const payload: FITBookingPayload = {
          itinerary_info: this.emailItems,
          request_message: this.query,
        };
        const confirmFITHotel = await this.$store.dispatch(
          "confirmFITHotels",
          payload
        );
        if (confirmFITHotel) {
          this.$emit("closeModal");
        }
        this.$store.commit("resetFITItems");
      } else {
        this.queryError = "Query can't be empty";
      }
    },
  },
});
</script>
